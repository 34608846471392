<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="newsDetailPageBox">
    <!-- 面包屑 -->
    <div class="crumbsBox">
      <router-link class="loogImg" :to="{ path: '/' }">
        <img src="@/assets/img/logo.png" alt="logo">
      </router-link>
      <div class="crumbs">
        <router-link :to="{path:'/'}">
          首页
        </router-link>
        <i>/</i>
        <router-link :to="{path:'/news/index'}">
          平台快报
        </router-link>
        <i>/</i>
        <span>快报详情</span>
      </div>
    </div>
    <div class="newsContent">
      <h3 class="title elp-1">
        {{ newsDetail.title }}
      </h3>
      <p class="timer">
        {{ newsDetail.createTime }}
      </p>
      <!-- 文章的内容 -->
      <!-- // eslint-disable-next-line vue/no-v-html -->
      <div class="content" v-html="newsDetail.content" />
    </div>
  </div>
</template>

<script>
import { getNewsByDetail } from '@/http/common'
export default {
  data() {
    return {
      newsDetail: {}
    }
  },
  mounted() {
    if (this.$route.query.id) {
      getNewsByDetail(this.$route.query.id, res => {
        this.newsDetail = { ...res.data }
      })
    }
  }
}
</script>

<style lang="scss">
.newsDetailPageBox {
  background-color: rgba(249, 249, 249, 1);
  .newsContent{
    width: $centerPlateWidth;
    margin: auto;
    min-height: 600px;
    padding: 40px 0;
    // .content img {
    //   // display: none !important;
    // }
    // content{
    //   // padding-bottom: 80px;
    //   // min-height: calc(100vh - 470px);
    // }
    .timer{
      padding: 9px 0 32px;
      font-size: 16px;
      color: #999999;
    }
    .title{
      color: #333;
      font-size: 40px;
      font-weight: 500;
    }
  }
  .crumbsBox {
    display: flex;
    align-items: center;
    width: $centerPlateWidth;
    padding: 32px 0 40px;
    margin: auto;
    .crumbs{
      display: flex;
      align-items: center;
      margin-left: 24px;
      height: 16px;
      a,i{
        color: #666666;
        font-size: 16px;
      }
      i{
        display: inline-block;
        margin: 0 8px;
        font-style: normal;
      }
      span{
        color: #333;
        font-size: 16px;
      }
    }
    .loogImg,img{
      // width: 126px;
      height: 34px;
    }
  }
}
</style>
